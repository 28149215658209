// Definitely need more work on refactorization.
import { TrashIcon } from "@heroicons/react/24/outline";
import { PrinterIcon } from "@heroicons/react/24/solid";
import { useApplicationModals } from "app/common/hooks";
import { BadgeButton, IconButton } from "app/components/atoms/button";
import { SearchInput } from "app/components/atoms/input";
import Table from "app/components/atoms/table";
import Typography from "app/components/atoms/typography";
import {
  useCollector,
  usePaginatedCollector,
} from "app/integration/common/hooks";
import MainLayout from "app/layouts/main";
import AppModals from "app/modals";
import { useEffect, useState } from "react";
import { findWhere as _findWhere, sortBy as _sortBy } from "underscore";
import { DateTime } from "luxon";
import { DateRangeInputMolecule } from "app/components/molecules/input";

const breadcrumbItems = [
  { label: "Settings", href: "#" },
  { label: "Menu Harian", href: "#" },
];

const minus7 = () => {
  const now = DateTime.now();
  const tujuhHariSebelumnya = now.minus({ days: 7 });

  return tujuhHariSebelumnya.toISODate();
};

const plus7 = () => {
  const now = DateTime.now();
  const tujuhHariKemudian = now.plus({ days: 7 });

  return tujuhHariKemudian.toISODate();
};

function sortDailyMenusForPresentation(dailyMenus) {
  const sortedDailyMenusByDate = {};
  dailyMenus?.forEach((dailyMenu) => {
    if (!Array.isArray(sortedDailyMenusByDate[dailyMenu.delivery_date])) {
      sortedDailyMenusByDate[dailyMenu.delivery_date] = [];
    }
    sortedDailyMenusByDate[dailyMenu.delivery_date].push(dailyMenu);
  });

  return sortedDailyMenusByDate;
}

function DailyMenuPage() {
  const { openModal, setPayloads } = useApplicationModals();

  // const [
  //   dailyMenus,
  //   setDailyMenus,
  //   dailyMenusLoader,
  //   collectDailyMenus,
  //   params,
  //   setParams,
  // ] = useDailyMenuCollector();

  const [
    menuCategories,
    setMenuCategories,
    menuCategoriesLoader,
    collectMenuCategories,
  ] = useCollector({ modelName: "menuCategory" });

  const [paramsDailyMenu, setParamsDailyMenu] = useState({
    page: 1,
    limit: 50,
    delivery_start: minus7(),
    delivery_end: plus7()
  });

  useEffect(() => {
    collectDailyMenus(paramsDailyMenu);
  }, [paramsDailyMenu]);

  const [
    dailyMenus,
    setDailyMenus,
    dailyMenusLoader,
    collectDailyMenus,
    dailyMenuPaginationProps,
  ] = usePaginatedCollector({
    modelName: "dailyMenu",
    params: paramsDailyMenu,
  });

  const [sortedDailyMenus, setSortedDailyMenus] = useState({});

  useEffect(() => {
    setSortedDailyMenus(sortDailyMenusForPresentation(dailyMenus));
  }, [dailyMenus]);

  return (
    <MainLayout
      activeSidebarNavigation="settings"
      activeSidebarChild="daily-menu"
      breadcrumbItems={breadcrumbItems}
      pageTitle="Menu Harian"
      headingButtons={[
        {
          label: "Tambah Menu",
          type: "button",
          onClick: () => openModal("dailyMenu.create"),
        },
      ]}
    >
      <AppModals
        items={["dailyMenu.create", "dailyMenu.update", "dailyMenu.delete"]}
        onSuccess={{
          "dailyMenu.create": () => collectDailyMenus(),
          "dailyMenu.update": () => collectDailyMenus(),
          "dailyMenu.delete": () => collectDailyMenus(),
        }}
      />
      <Table.Container>
        <Table.UtilitiesWrapper>
          <Table.SearchBarWrapper>
            {/* <Table.SearchBarItemWrapper>
              <SearchInput placeholder="Search" />
            </Table.SearchBarItemWrapper> */}
            <div className="col-span-2">
              <DateRangeInputMolecule
                label="Tanggal Kirim"
                from={paramsDailyMenu?.delivery_start}
                to={paramsDailyMenu?.delivery_end}
                onFromChange={(e) => {
                  setParamsDailyMenu({
                    ...paramsDailyMenu,
                    delivery_start: e.target.value,
                  });
                }}
                onToChange={(e) => {
                  setParamsDailyMenu({
                    ...paramsDailyMenu,
                    delivery_end: e.target.value,
                  });
                }}
              />
            </div>
          </Table.SearchBarWrapper>
          {/* <Table.UtilitiesButtonWrapper>
            <IconButton icon={PrinterIcon} />
          </Table.UtilitiesButtonWrapper> */}
        </Table.UtilitiesWrapper>
        <Table.Wrapper asIndex={true}>
          <Table.Header>
            <Table.HeaderRow>
              <Table.Heading>Tanggal</Table.Heading>
              {_sortBy(menuCategories, "id")?.map((menuCategory, key) => (
                <Table.Heading key={key}>{menuCategory.name}</Table.Heading>
              ))}
              <Table.Heading></Table.Heading>
            </Table.HeaderRow>
          </Table.Header>
          <Table.Body>
            {Object.keys(sortedDailyMenus)
              .sort()
              .reverse()
              .map((date, key) => (
                <Table.BodyRow key={key}>
                  <Table.Cell>
                    <div className="block">
                      <div>
                        <Typography.Date date={date} />
                      </div>
                      <div>
                        <Typography.Day date={date} />
                      </div>
                    </div>
                  </Table.Cell>
                  {_sortBy(menuCategories, "id").map(
                    (menuCategory, nestedKey) => (
                      <Table.Cell key={nestedKey}>
                        <div className="flex gap-2 justify-between">
                          <div className="block">
                            <div className="font-semibold">
                              {
                                _findWhere(dailyMenus, {
                                  product_category_id: menuCategory.id,
                                  delivery_date: date,
                                })?.name
                              }
                            </div>
                            <div>
                              <Typography.Currency
                                number={
                                  _findWhere(dailyMenus, {
                                    product_category_id: menuCategory.id,
                                    delivery_date: date,
                                  })?.price
                                }
                              />
                            </div>
                          </div>
                          <div className="flex items-center">
                            {_findWhere(dailyMenus, {
                              product_category_id: menuCategory.id,
                              delivery_date: date,
                            }) !== undefined && (
                              <>
                                <BadgeButton
                                  onClick={() => {
                                    setPayloads("dailyMenu.update", {
                                      id: _findWhere(dailyMenus, {
                                        product_category_id: menuCategory.id,
                                        delivery_date: date,
                                      })?.id,
                                    });
                                    openModal("dailyMenu.update");
                                  }}
                                >
                                  Edit
                                </BadgeButton>
                                <IconButton
                                  icon={TrashIcon}
                                  onClick={() => {
                                    setPayloads("dailyMenu.delete", {
                                      id: _findWhere(dailyMenus, {
                                        product_category_id: menuCategory.id,
                                        delivery_date: date,
                                      })?.id,
                                    });
                                    openModal("dailyMenu.delete");
                                  }}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </Table.Cell>
                    )
                  )}
                </Table.BodyRow>
              ))}
          </Table.Body>
        </Table.Wrapper>
      </Table.Container>
    </MainLayout>
  );
}

export default DailyMenuPage;
