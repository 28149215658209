import { useParams, useNavigate } from "react-router-dom";
import { useUpdateForm } from "app/integration/common/hooks";
import * as Yup from "yup";
import api from "app/integration/api";
import { useFetcher } from "app/integration/common/hooks";
import { useEffect } from "react";
import { useCollector } from "app/integration/common/hooks";
import Directory from "app/components/atoms/directory";
import { ClosableCard } from "app/components/atoms/card";
import { SearchInput } from "app/components/atoms/input";
import { Formik, FieldArray, getIn } from "formik";
import { InputMolecule } from "app/components/molecules/form";
import { SelectMolecule } from "app/components/molecules/select";
import { InputLabel } from "app/components/atoms/form";
import { Button, DottedOutlineButton } from "app/components/atoms/button";
import Typography from "app/components/atoms/typography";

function CustomerDetailSubPage({
  onSuccess = () => {},
  customers = [],
  query = "",
  onQueryChange = () => {},
}) {
  let { id } = useParams();
  const navigate = useNavigate();

  const handleSuccess = () => {
    onSuccess();
  };

  const { initialValues, setInitialValues, validationSchema, onSubmit } =
    useUpdateForm({
      initialValues: {
        id: null,
        firstName: "",
        middleName: "",
        lastName: "",
        nickName: "",
        credit: 0,
        plafon: 0,
        addresses: [],
      },
      validationSchema: Yup.object().shape({
        firstName: Yup.string().required("Nama Customer harus diisi"),
        middleName: Yup.string(),
        lastName: Yup.string(),
        nickName: Yup.string(),
        credit: Yup.number(),
        plafon: Yup.number(),
        addresses: Yup.array().of(
          Yup.object().shape({
            address: Yup.string().required("Alamat harus diisi"),
            state: Yup.string(),
            city: Yup.string(),
            district: Yup.string(),
            subDistrict: Yup.string(),
            shipmentCostCategoryId: Yup.number().required(
              "Kategori Ongkir harus diisi"
            ),
            delivery_time: Yup.number(),
            note_order: Yup.string(),
            parking_fee: Yup.number(),
          })
        ),
      }),
      onSubmit: async (values) => {
        await api.customer.update(
          values.id,
          values.firstName,
          values.lastName,
          values.middleName,
          values.nickName,
          values.email,
          values.phoneNumber,
          values.credit,
          values.plafon,
          values.addresses.map((address) => ({
            id: address.id,
            address: address.address,
            state: address.state,
            city: address.city,
            district: address.district,
            sub_district: address.subDistrict,
            delivery_category_id: address.shipmentCostCategoryId,
            delivery_time: address.delivery_time,
            note_order: address.note_order,
            parking_fee: address.parking_fee,
          }))
        );
      },
      onSuccess: handleSuccess,
    });

  const [customer, setCustomer, loader, fetch] = useFetcher({
    modelName: "customer",
    payload: { id },
  });

  useEffect(() => {
    setInitialValues({
      id: customer.id,
      firstName: customer.first_name,
      middleName: customer.middle_name,
      lastName: customer.last_name,
      nickName: customer.nick_name,
      email: customer.email,
      phoneNumber: customer.phone_number,
      credit: customer.credit,
      plafon: customer.plafon,
      addresses: customer.Addresses?.map((address) => ({
        id: address.id,
        address: address.address,
        state: address.state,
        city: address.city,
        district: address.district,
        subDistrict: address.sub_district,
        shipmentCostCategoryId: address.delivery_category_id,
        delivery_time: address.delivery_time,
        note_order: address?.note_order ? address?.note_order : "",
        parking_fee: address.parking_fee,
      })),
    });
  }, [customer]);

  const [shipmentCostCategories] = useCollector({
    modelName: "shipmentCostCategory",
  });

  return (
    <div className="flex">
      <Directory.Container>
        <Directory.UtilitiesWrapper>
          <Directory.SearchBarWrapper>
            <Directory.SearchBarItemWrapper>
              <SearchInput
                placeholder="Search Customer"
                type="text"
                query={query}
                onChange={(e) => { onQueryChange(e.target.value); }}
              />
            </Directory.SearchBarItemWrapper>
          </Directory.SearchBarWrapper>
        </Directory.UtilitiesWrapper>
        <Directory.Wrapper asIndex={true}>
          <Directory.Header>Customer</Directory.Header>
          <Directory.Body>
            {[...customers]
              ?.sort(function (a, b) {
                if (a.first_name?.toLowerCase() < b.first_name?.toLowerCase())
                  return -1;
                if (a.first_name?.toLowerCase() > b.first_name?.toLowerCase())
                  return 1;
                return 0;
              })
              .map((customer, key) => (
                <Directory.Row
                  key={key}
                  active={customer.id === +id}
                  onClick={(e) => navigate("/customer/detail/" + customer.id)}
                >
                  {customer.first_name} {customer.middle_name}{" "}
                  {customer.last_name}
                </Directory.Row>
              ))}
          </Directory.Body>
        </Directory.Wrapper>
      </Directory.Container>
      <ClosableCard
        grow={true}
        asMainContent={true}
        onClose={(e) => navigate("/customer")}
      >
        <Formik
          {...{ initialValues, validationSchema, onSubmit }}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            status,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form className="space-y-2 p-2" onSubmit={handleSubmit}>
              <div className="grid grid-cols-2 gap-4">
                <InputMolecule
                  label="Nama Depan"
                  type="text"
                  name="firstName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  errorMessage={
                    errors.firstName && touched.firstName && errors.firstName
                  }
                />
                <InputMolecule
                  label="Nama Tengah"
                  type="text"
                  name="middleName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.middleName}
                  errorMessage={
                    errors.middleName && touched.middleName && errors.middleName
                  }
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <InputMolecule
                  label="Nama Belakang"
                  type="text"
                  name="lastName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  errorMessage={
                    errors.lastName && touched.lastName && errors.lastName
                  }
                />
                <InputMolecule
                  label="Nama Panggilan"
                  type="text"
                  name="nickName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.nickName}
                  errorMessage={
                    errors.nickName && touched.nickName && errors.nickName
                  }
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <InputMolecule
                  label="Email"
                  type="text"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  errorMessage={errors.email && touched.email && errors.email}
                />
                <InputMolecule
                  label="No. HP"
                  type="text"
                  name="phoneNumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phoneNumber}
                  errorMessage={
                    errors.phoneNumber &&
                    touched.phoneNumber &&
                    errors.phoneNumber
                  }
                />
              </div>

              {/* <div>
                <hr className="my-5" />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <InputMolecule
                  label="Kredit"
                  type="number"
                  name="credit"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.credit}
                  errorMessage={
                    errors.credit && touched.credit && errors.credit
                  }
                />
                <InputMolecule
                  label="Plafon"
                  type="number"
                  name="plafon"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.plafon}
                  errorMessage={
                    errors.plafon && touched.plafon && errors.plafon
                  }
                />
              </div> */}

              <div>
                <hr className="my-5" />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <InputLabel>Kredit</InputLabel>
                  <div className="px-2 border border-gray-200 bg-gray-100 rounded-lg py-2">
                    <Typography.Currency number={values.credit} />
                  </div>
                </div>
                <div>
                  <InputLabel>Plafon</InputLabel>
                  <div className="px-2 border border-gray-200 bg-gray-100 rounded-lg py-2">
                    <Typography.Currency number={values.plafon} />
                  </div>
                </div>
              </div>

              <div>
                <hr className="my-5" />
              </div>
              <div>
                <InputLabel>Alamat</InputLabel>
                <FieldArray name="addresses">
                  {(arrayHelpers) => (
                    <div className="space-y-4">
                      {values.addresses?.map((address, addressKey) => (
                        <ClosableCard
                          key={addressKey}
                          onClose={() => arrayHelpers.remove(addressKey)}
                        >
                          <div className="space-y-2 p-2">
                            <InputMolecule
                              label={"Alamat " + (addressKey + 1)}
                              type="text"
                              name={`addresses[${addressKey}].address`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={address.address}
                              errorMessage={
                                getIn(
                                  errors,
                                  `addresses[${addressKey}].address`
                                ) &&
                                getIn(
                                  touched,
                                  `addresses[${addressKey}].address`
                                ) &&
                                getIn(
                                  errors,
                                  `addresses[${addressKey}].address`
                                )
                              }
                            />
                            {/* <InputMolecule
                                    label="Negara"
                                    type="text"
                                    name={`addresses[${addressKey}].state`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={address.state}
                                    errorMessage={
                                      getIn(errors, `addresses[${addressKey}].state`) && 
                                      getIn(touched, `addresses[${addressKey}].state`) &&
                                      getIn(errors, `addresses[${addressKey}].state`)
                                    }
                                  /> */}
                            <InputMolecule
                              label="Kota"
                              type="text"
                              name={`addresses[${addressKey}].city`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={address.city}
                              errorMessage={
                                getIn(
                                  errors,
                                  `addresses[${addressKey}].city`
                                ) &&
                                getIn(
                                  touched,
                                  `addresses[${addressKey}].city`
                                ) &&
                                getIn(errors, `addresses[${addressKey}].city`)
                              }
                            />
                            {/* <InputMolecule
                                    label="Kecamatan"
                                    type="text"
                                    name={`addresses[${addressKey}].district`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={address.district}
                                    errorMessage={
                                      getIn(errors, `addresses[${addressKey}].district`) && 
                                      getIn(touched, `addresses[${addressKey}].district`) &&
                                      getIn(errors, `addresses[${addressKey}].district`)
                                    }
                                  /> */}
                            {/* <InputMolecule
                                    label="Kelurahan"
                                    type="text"
                                    name={`addresses[${addressKey}].subDistrict`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={address.subDistrict}
                                    errorMessage={
                                      getIn(errors, `addresses[${addressKey}].subDistrict`) && 
                                      getIn(touched, `addresses[${addressKey}].subDistrict`) &&
                                      getIn(errors, `addresses[${addressKey}].subDistrict`)
                                    }
                                  /> */}
                            <SelectMolecule
                              label="Kategori Ongkir"
                              name={`addresses[${addressKey}].shipmentCostCategoryId`}
                              options={shipmentCostCategories?.map(
                                (shipmentCostCategory) => ({
                                  value: shipmentCostCategory.id,
                                  label: shipmentCostCategory.name,
                                })
                              )}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={address.shipmentCostCategoryId}
                              errorMessage={
                                getIn(
                                  errors,
                                  `addresses[${addressKey}].shipmentCostCategoryId`
                                ) &&
                                getIn(
                                  touched,
                                  `addresses[${addressKey}].shipmentCostCategoryId`
                                ) &&
                                getIn(
                                  errors,
                                  `addresses[${addressKey}].shipmentCostCategoryId`
                                )
                              }
                              absolute={true}
                            />
                            <SelectMolecule
                              label="Waktu Kirim"
                              name={`addresses[${addressKey}].delivery_time`}
                              options={[
                                {
                                  value: 0,
                                  label: "Kirim Pagi",
                                },
                                {
                                  value: 1,
                                  label: "Default",
                                },
                              ]}
                              absolute={true}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={address.delivery_time}
                              errorMessage={
                                getIn(
                                  errors,
                                  `addresses[${addressKey}].delivery_time`
                                ) &&
                                getIn(
                                  touched,
                                  `addresses[${addressKey}].delivery_time`
                                ) &&
                                getIn(
                                  errors,
                                  `addresses[${addressKey}].delivery_time`
                                )
                              }
                            />
                            <InputMolecule
                              label="Catatan Order"
                              type="text"
                              name={`addresses[${addressKey}].note_order`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={address.note_order}
                              errorMessage={
                                getIn(
                                  errors,
                                  `addresses[${addressKey}].note_order`
                                ) &&
                                getIn(
                                  touched,
                                  `addresses[${addressKey}].note_order`
                                ) &&
                                getIn(
                                  errors,
                                  `addresses[${addressKey}].note_order`
                                )
                              }
                            />
                            <InputMolecule
                              label="Biaya Parkir"
                              type="number"
                              name={`addresses[${addressKey}].parking_fee`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={address.parking_fee}
                              errorMessage={
                                getIn(
                                  errors,
                                  `addresses[${addressKey}].parking_fee`
                                ) &&
                                getIn(
                                  touched,
                                  `addresses[${addressKey}].parking_fee`
                                ) &&
                                getIn(
                                  errors,
                                  `addresses[${addressKey}].parking_fee`
                                )
                              }
                            />
                          </div>
                        </ClosableCard>
                      ))}
                      <div>
                        <div className="my-8">
                          <DottedOutlineButton
                            type="button"
                            onClick={(e) =>
                              arrayHelpers.push({
                                address: "",
                                state: "",
                                city: "",
                                district: "",
                                subDistrict: "",
                                shipmentCostCategoryId: null,
                                delivery_time: 1,
                                note_order: "",
                                parking_fee: 0,
                              })
                            }
                          >
                            Tambah Alamat
                          </DottedOutlineButton>
                        </div>
                      </div>
                    </div>
                  )}
                </FieldArray>
              </div>
              <div className="fixed bottom-2 left-0 right-0 sm:absolute sm:left-auto sm:right-4 sm:bottom-4 sm:w-auto z-40 flex justify-end border-t border-gray-100 bg-white sm:bg-transparent p-4 sm:p-0 dark:border-gray-700 dark:bg-gray-800 print:hidden sm:rounded-b-lg sm:border-0 ">
                <Button type="submit">Ubah Customer</Button>
              </div>
            </form>
          )}
        </Formik>
      </ClosableCard>
    </div>
  );
}

export default CustomerDetailSubPage;
